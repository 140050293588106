.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  width: 40vmin;
  object-fit: contain;
  pointer-events: none;
  margin-bottom: 3rem;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.Appbody {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  font: 'Futura'
}

.Appbody h1 {
  font-size: calc(40px + 5vmin);
  color: black;
  font-style: italic;
}

.links-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 2rem;
  margin-bottom: 3rem;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .links-container {
    flex-direction: row;
  }
}

.Appbody headings {
  font-size: calc(24px + 1vmin);
  color: black;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 1rem;
  cursor: pointer;
  text-decoration: none;
}

.Appbody a {
  text-decoration: none;
  color: inherit;
}

.Appbody a:hover {
  text-decoration: underline;
}

.Appbody p {
  font-size: calc(14px + 1vmin);
  color: black;
  /* font-style: italic; */
  max-width: 45%;
}

.Appbody h3 {
  font-size: calc(14px + 1vmin);
  color: black;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 3rem;
}

.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 3rem;
}

@keyframes App-logo-spin {
    0% {
      transform: rotate(-20deg);
    }
    50% {
      transform: rotate(20deg);
    }
    100% {
      transform: rotate(-20deg);
    }
}